// types
import { IConfigObj } from '@makemydeal/dr-common-utils';
import { ChooseVehicleConfig, ChooseVehicleState, ChooseVehicleStateTree } from '../types/stateTreeTypes';

export const getConfig = (state: ChooseVehicleStateTree): ChooseVehicleConfig => {
    return state.config;
};

export const getConfigServices = (state: ChooseVehicleStateTree) => {
    return getConfig(state).services;
};

export const getServicesBff = (state: ChooseVehicleStateTree): IConfigObj => {
    return getConfigServices(state).bff;
};

export const getChooseVehicle = (state: ChooseVehicleStateTree): ChooseVehicleState => {
    return state.chooseVehicle || {};
};

export const getCommonOrgId = (state: ChooseVehicleStateTree): string => {
    return getChooseVehicle(state).commonOrgId || '';
};

export const isCostVisible = (): boolean => {
    return false;
};
