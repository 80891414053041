// externals
import { Redirect } from 'react-router';
import { StyleSheetManager as ScStyleSheetManager, ThemeProvider } from 'styled-components';

// libraries
import type { FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// components
import { BodyContainer } from './BodyContainer';

// configs
import { changeVehicleConfiguration } from '../activities/change-vehicle';

export type RoutesProps = {
    toggles: FeatureTogglesAndUrlOnly;
};

const StyleSheetManager = ScStyleSheetManager as any;

export const Routes: React.FC<RoutesProps> = () => {
    const theme = useInterstateTheme();

    const getLandingPage = () => {
        return '/';
    };

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <>
                    <Redirect to={getLandingPage()} />
                    <BodyContainer>
                        <changeVehicleConfiguration.routingComponent />
                    </BodyContainer>
                </>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
