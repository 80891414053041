// externals
import type { Reducer } from 'redux';

// types
import { ChooseVehicleState } from '../types/stateTreeTypes';

// action types
import { initActionTypes } from '@makemydeal/dr-shared-store';

export type ChooseVehilceReducer = Reducer<ChooseVehicleState>;

export const CHOOSE_VEHICLE_INITIAL_STATE: ChooseVehicleState = {};

export const reducer: Reducer<ChooseVehicleState> = (state: ChooseVehicleState = CHOOSE_VEHICLE_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                commonOrgId: action.payload.commonOrgId
            };
        }
        default: {
            return state;
        }
    }
};
