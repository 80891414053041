import {
    AUTH_SCOPE_BRIDGE_SIGN_IN,
    AUTH_SCOPE_OPEN_ID,
    AuthConfigCallback,
    OKTA_CONFIG_ISSUER_NON_PROD,
    OKTA_CONFIG_ISSUER_PROD,
    OktaAuthBaseConfig
} from '@makemydeal/dr-shared-components';
import { CLIENT_ID, NON_PROD_CLIENT_ID, OKTA_CONFIG_ID_NON_PROD, OKTA_CONFIG_ID_PROD } from './constants';

const baseConfig: OktaAuthBaseConfig = {
    id: OKTA_CONFIG_ID_NON_PROD,
    issuer: OKTA_CONFIG_ISSUER_NON_PROD,
    clientId: NON_PROD_CLIENT_ID,
    // The bridge configuration for Choose Vehicle does not have an App Scope, when it does, we can enable it here
    scopes: [AUTH_SCOPE_BRIDGE_SIGN_IN, AUTH_SCOPE_OPEN_ID /*, APP_SCOPE */],
    system: 'CSI'
};

const productionConfig: OktaAuthBaseConfig = {
    ...baseConfig,
    id: OKTA_CONFIG_ID_PROD,
    issuer: OKTA_CONFIG_ISSUER_PROD,
    clientId: CLIENT_ID
};

export const getAuthConfig: AuthConfigCallback = (isProduction: boolean) => {
    return isProduction ? productionConfig : baseConfig;
};
