// istanbul ignore file

import styled from 'styled-components';

export const BannerWrapper = styled.div`
    width: auto;
`;

export const StyledBodyContainer = styled.div`
    padding: 24px;
`;
