// externals
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// utils
import { loadingIndicatorUtils } from '@makemydeal/dr-shared-ui-utils';
import { setupResizeObserver } from '../../utils/setupResizeObserver';
import { PostMsgTypes, sendReadyMsg, sendWindowHeightMsg } from '../../activities/change-vehicle/postMessageUtils/postMessageUtils';

// store
import { initialLoad, initFromPostMessageSuccess } from '../../actions/bootstrapAppActionCreators';
import { sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';
import { vehicleChangeFailure, vehicleChangeSuccess } from '@makemydeal/dr-activities-change-vehicle';

// types/constants
import { BootstrapLoadingState } from '@makemydeal/dr-shared-types';

// components
import { LoadingPage } from '../loadingPage/LoadingPage';

export const Bootstrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const loadingState = useSelector(sharedBootstrapSelectors.getBootstrapLoadingState);

    const handlePostMessage = useCallback(
        (event: MessageEvent<any>) => {
            const postMessage = event.data || {};

            switch (postMessage.type) {
                case PostMsgTypes.CHV_INIT: {
                    dispatch(initFromPostMessageSuccess(postMessage.payload));

                    break;
                }
                case PostMsgTypes.CHV_CHANGE_FAILURE: {
                    dispatch(vehicleChangeFailure());

                    break;
                }
                case PostMsgTypes.CHV_CHANGE_SUCCESS: {
                    dispatch(vehicleChangeSuccess());

                    break;
                }
            }
        },
        [dispatch]
    );

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    useEffect(() => {
        dispatch(initialLoad());
        window.addEventListener('message', handlePostMessage, false);

        // Notify to parent, the app is ready to listen messages
        sendReadyMsg();

        // Initialize ResizeObserver to send iframe height to parent as postMessage
        // resize function below will be covered in testcafe
        /* istanbul ignore next */
        const resizeObserver = setupResizeObserver((height) => {
            sendWindowHeightMsg(height);
        });

        return () => {
            window.removeEventListener('message', handlePostMessage, false);
            resizeObserver.disconnect();
        };
    }, [handlePostMessage, dispatch]);

    useEffect(() => {
        if (loadingState !== BootstrapLoadingState.LoadedSuccessfully) return;

        loadingIndicatorUtils.removeAllLoadingIndicatorPanels();
    }, [loadingState]);

    const shouldShowLoader = loadingState === BootstrapLoadingState.Loading;
    const shouldShowReadyContent = loadingState === BootstrapLoadingState.LoadedSuccessfully;

    return (
        <>
            {shouldShowLoader && <LoadingPage />}
            {shouldShowReadyContent && children}
            {!shouldShowReadyContent && !shouldShowLoader && 'Error occurred bootstrapping the app - no init data'}
        </>
    );
};
