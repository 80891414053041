const APP_DOMAIN = 'CHOOSE_VEHICLE';

export const CHV_READY = `${APP_DOMAIN}/READY`;
export const CHV_INIT = `${APP_DOMAIN}/INIT`;
export const CHV_CHANGE_FAILURE = `${APP_DOMAIN}/CHANGE_FAILURE`;
export const CHV_CHANGE_SUCCESS = `${APP_DOMAIN}/CHANGE_SUCCESS`;
export const CHV_SELECTION_CHANGED = `${APP_DOMAIN}/SELECTION_CHANGED`;
export const CHV_INIT_SUCCESS = `${APP_DOMAIN}/INIT_SUCCESS`;
export const CHV_SEARCH_SUCCESS = `${APP_DOMAIN}/SEARCH_SUCCESS`;
export const CHV_SEARCH_FAILURE = `${APP_DOMAIN}/SEARCH_FAILURE`;
export const CHV_WINDOW_HEIGHT_CHANGED = `${APP_DOMAIN}/WINDOW_HEIGHT_CHANGED`;
export const CHV_CATALOG_VEHICLE_SUCCESS = `${APP_DOMAIN}/CATALOG_VEHICLE_SUCCESS`;
export const CHV_CATALOG_VEHICLE_FAILURE = `${APP_DOMAIN}/CATALOG_VEHICLE_FAILURE`;
