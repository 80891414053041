import { AnyFSA, FeatureToggles } from '@makemydeal/dr-platform-shared';

import { ReduxMiddleware, ReduxNext, ReduxStore, bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { CommonOrgDealerVehicle, payloadUtils } from '@makemydeal/dr-shared-ui-utils';
import {
    ConfigChangeVehicle,
    MiddlewareManager,
    NEW_VEHICLE_SELECTED,
    FETCH_CVI_REQUEST,
    FETCH_CVI_FAILURE,
    SEARCH_VEHICLE_REQUEST,
    SEARCH_VEHICLE_FAILURE,
    PROCESS_CVI_VEHICLES,
    UPDATE_VEHICLE_CUSTOM_DATA,
    UPDATE_CUSTOM_VEHICLE,
    FETCH_CATALOG_VEHICLES_SUCCESS,
    CLEAR_CATALOG_VEHICLES,
    FETCH_CATALOG_VEHICLES_FAILURE
} from '@makemydeal/dr-activities-change-vehicle';

import { changeVehicleSelectors, configureChangeVehicle } from './config';
import { getServicesBff } from '../../selectors/chooseVehicleAppSelectors';
import { ChooseVehicleStateTree } from '../../types/stateTreeTypes';
import {
    sendCatalogVehicleFailureMsg,
    sendInitSuccessMsg,
    sendCatalogVehicleSuccessMsg,
    sendSearchFailureMsg,
    sendSearchSuccessMsg,
    sendSelectionChangedMsg
} from './postMessageUtils/postMessageUtils';

// keep a cached version of the middleware once we're able to build it.
// exported to support testing
export let cachedChangeVehicleMiddlewareMgr: MiddlewareManager | undefined;

// needed for testing
export const changeVehicleMiddlewareClearCache = () => (cachedChangeVehicleMiddlewareMgr = undefined);

export const changeVehicleMiddleware: ReduxMiddleware<ChooseVehicleStateTree> =
    (store: ReduxStore<ChooseVehicleStateTree>) => (next: ReduxNext) => (action: AnyFSA) => {
        switch (action.type) {
            case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
                // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
                const state = store.getState();
                const bff = getServicesBff(state);
                const featureToggles: FeatureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);

                const initConfig: ConfigChangeVehicle = {
                    services: {
                        bff
                    },
                    featureToggles
                };

                cachedChangeVehicleMiddlewareMgr = configureChangeVehicle(initConfig);
                sendInitSuccessMsg();

                break;
            }
            case NEW_VEHICLE_SELECTED: {
                const state = store.getState();
                const selectedVehicleVin = action.payload.vehicle.vin;

                // Case 1: Search Vehicle
                if (selectedVehicleVin) {
                    const selectedVehicleWithCustomData =
                        changeVehicleSelectors.getVehicleWithCustomData(selectedVehicleVin)(state);
                    sendSelectionChangedMsg(selectedVehicleWithCustomData);
                }

                // Case 2: Build Vehicle
                else {
                    const selectedVehicleData = action.payload.vehicle;
                    sendSelectionChangedMsg(selectedVehicleData);
                }

                break;
            }
            case UPDATE_CUSTOM_VEHICLE: {
                const state = store.getState();
                const selectedVehicle = changeVehicleSelectors.getSelectedVehicle(state);

                if (action.payload && Object.keys(action.payload).length) {
                    const { msrp: retailPrice, odometer: mileage, sellingPrice: listedPrice } = action.payload;

                    sendSelectionChangedMsg({
                        ...selectedVehicle,
                        ...(retailPrice !== undefined && { retailPrice }),
                        ...(mileage !== undefined && { mileage }),
                        ...(listedPrice !== undefined && { listedPrice })
                    });
                }

                break;
            }
            case UPDATE_VEHICLE_CUSTOM_DATA: {
                const state = store.getState();
                const selectedVehicle = changeVehicleSelectors.getSelectedVehicle(state);
                const { listedPrice, mileage } = action.payload.vehicleCustomData;

                sendSelectionChangedMsg({
                    ...selectedVehicle,
                    ...(listedPrice !== undefined && { listedPrice }),
                    ...(mileage !== undefined && { mileage })
                });

                break;
            }
            case SEARCH_VEHICLE_REQUEST:
            case FETCH_CVI_REQUEST:
            case CLEAR_CATALOG_VEHICLES: {
                sendSelectionChangedMsg(null);

                break;
            }
            case PROCESS_CVI_VEHICLES: {
                const vehicles = action.payload;
                sendSearchSuccessMsg(vehicles);

                break;
            }
            case FETCH_CVI_FAILURE:
            case SEARCH_VEHICLE_FAILURE: {
                sendSearchFailureMsg(action.payload);

                break;
            }
            case FETCH_CATALOG_VEHICLES_SUCCESS: {
                const catalogVehicles = action.payload.filter(({ chromeId }: CommonOrgDealerVehicle) => chromeId);
                sendCatalogVehicleSuccessMsg({ hasVehicles: !!catalogVehicles.length });

                break;
            }
            case FETCH_CATALOG_VEHICLES_FAILURE: {
                const error = {
                    ...action.payload
                };
                sendCatalogVehicleFailureMsg(error);

                break;
            }
        }

        if (cachedChangeVehicleMiddlewareMgr) {
            cachedChangeVehicleMiddlewareMgr.middleware(store)(next)(action);
        } else {
            next(action);
        }
    };
