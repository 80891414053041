import { IVehicle } from '@makemydeal/dr-platform-types';
import * as PostMsgTypes from './types/postMessageTypes';

export * as PostMsgTypes from './types/postMessageTypes';

const sendParentPostMessage = <T>(payload: T) => window.parent.postMessage(payload, '*');

export const sendReadyMsg = (): void => {
    sendParentPostMessage({ type: PostMsgTypes.CHV_READY });
};

type InitSuccessPayload = {
    type: typeof PostMsgTypes.CHV_INIT_SUCCESS;
    payload: Record<string, never>;
};

export const sendInitSuccessMsg = (): void => {
    sendParentPostMessage<InitSuccessPayload>({
        type: PostMsgTypes.CHV_INIT_SUCCESS,
        payload: {}
    });
};

type SearchSuccessPayload = {
    type: typeof PostMsgTypes.CHV_SEARCH_SUCCESS;
    payload: { vehicles: IVehicle[] };
};

export const sendSearchSuccessMsg = (vehicles: IVehicle[]): void => {
    sendParentPostMessage<SearchSuccessPayload>({
        type: PostMsgTypes.CHV_SEARCH_SUCCESS,
        payload: { vehicles }
    });
};

type SearchFailurePayload = {
    type: typeof PostMsgTypes.CHV_SEARCH_FAILURE;
    payload: { error: unknown };
};

export const sendSearchFailureMsg = (error: unknown): void => {
    sendParentPostMessage<SearchFailurePayload>({
        type: PostMsgTypes.CHV_SEARCH_FAILURE,
        payload: { error }
    });
};

type CatalogVehicleFailurePayload = {
    type: typeof PostMsgTypes.CHV_CATALOG_VEHICLE_FAILURE;
    payload: { error: unknown };
};

export const sendCatalogVehicleFailureMsg = (error: unknown): void => {
    sendParentPostMessage<CatalogVehicleFailurePayload>({
        type: PostMsgTypes.CHV_CATALOG_VEHICLE_FAILURE,
        payload: { error }
    });
};

type CatalogVehicleSuccessPayload = {
    type: typeof PostMsgTypes.CHV_CATALOG_VEHICLE_SUCCESS;
    payload: { status: { hasVehicles: boolean } };
};

export const sendCatalogVehicleSuccessMsg = (status: { hasVehicles: boolean }): void => {
    sendParentPostMessage<CatalogVehicleSuccessPayload>({
        type: PostMsgTypes.CHV_CATALOG_VEHICLE_SUCCESS,
        payload: { status }
    });
};

type SelectionChangedPayload = {
    type: typeof PostMsgTypes.CHV_SELECTION_CHANGED;
    payload: { vehicle: IVehicle | null };
};

export const sendSelectionChangedMsg = (vehicle: IVehicle | null): void => {
    sendParentPostMessage<SelectionChangedPayload>({
        type: PostMsgTypes.CHV_SELECTION_CHANGED,
        payload: { vehicle }
    });
};

type WindowHeightChangedPayload = {
    type: typeof PostMsgTypes.CHV_WINDOW_HEIGHT_CHANGED;
    payload: { height: number };
};

export const sendWindowHeightMsg = (height: number): void => {
    sendParentPostMessage<WindowHeightChangedPayload>({
        type: PostMsgTypes.CHV_WINDOW_HEIGHT_CHANGED,
        payload: { height }
    });
};
