/**
 * PURPOSE: Listens for specific actions and determines when a route change should be performed.
 */

// externals
import { push } from 'connected-react-router';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { NavigationActions } from '@makemydeal/dr-activities-common';
import { ReduxMiddleware, ReduxNext, ReduxStore } from '@makemydeal/dr-shared-store';

// interfaces/types
import { ChooseVehicleStateTree } from '../types/stateTreeTypes';

function processAction(action: AnyFSA, store: ReduxStore<ChooseVehicleStateTree>) {
    switch (action.type) {
        case NavigationActions.NAVIGATE_TO: {
            const routeIntent = action.payload;
            store.dispatch(push(routeIntent));
            break;
        }

        default: {
            break;
        }
    }
}

export const navigationMiddleware: ReduxMiddleware<ChooseVehicleStateTree> =
    (store: ReduxStore<ChooseVehicleStateTree>) => (next: ReduxNext) => (action: AnyFSA) => {
        next(action);

        if (action && action.type) {
            processAction(action, store);
        }
    };
