// externals
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'connected-react-router';

// utils
import { historyInstance } from '@makemydeal/dr-shared-store';

import { bootstrapMiddleware } from '../middleware/bootstrapMiddleware';

export const getInitialMiddleware = () => [thunk, apiMiddleware, bootstrapMiddleware, routerMiddleware(historyInstance)];
