/**
 * PURPOSE: Provide reducers that are required for the initial boostrap of the application.  These reducers cannot
 *   be selectively loading based on feature toggle flags.  For the the other reducers that can be conditionally
 *   added take a look at `remainingReducers.ts`.
 */

// libraries
import { navigationReducer } from '@makemydeal/dr-activities-common';
import { locHrefUtil, apiConfigHelper, CHOOSE_VEHICLE_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// types
import { ChooseVehicleConfig } from '../types/stateTreeTypes';

// reducers
import { configReducer, sharedBootstrapReducer } from '@makemydeal/dr-shared-store';
import { togglesWithOverridesReducer } from './featureTogglesWithOverridesReducer';

export const initialState: ChooseVehicleConfig = {
    services: {
        bff: {
            host: '',
            protocol: ''
        }
    }
};

const handleBootstrapInitialLoad: configReducer.BuildConfigReducerBootstrapInitialLoadCallback<ChooseVehicleConfig> = (
    appPrefix,
    action,
    state
) => {
    // get urls based on windows location
    const bffApiConfig = apiConfigHelper.buildBffApiConfig(appPrefix, locHrefUtil.getLocHref());
    const shallowCloned = { ...state };
    shallowCloned.services = {
        ...state.services,
        bff: {
            ...state.services.bff,
            ...bffApiConfig.configObj
        }
    };

    return shallowCloned;
};

export const initialReducers = {
    sharedBootstrap: sharedBootstrapReducer.reducer,
    config: configReducer.buildConfigReducer(CHOOSE_VEHICLE_APP_PREFIX, initialState, handleBootstrapInitialLoad),
    featureToggles: togglesWithOverridesReducer,
    navigation: navigationReducer
};
