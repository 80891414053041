import { SelectVehicleContainer } from './components/SelectVehicleContainer';
import { changeVehicleReducer } from './config';
import { APP_NAME } from './constants';
import { changeVehicleMiddleware } from './middleware';

export const changeVehicleConfiguration = {
    name: APP_NAME,
    middleware: changeVehicleMiddleware,
    reducer: changeVehicleReducer,
    routingComponent: SelectVehicleContainer
};
