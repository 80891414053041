// below function is partially covered in unit test. But mocking it to test callback is not worth it.
// It is being covered in testcafe test.
/* istanbul ignore next */
export const setupResizeObserver = (callback: (height: number) => void) => {
    const resizeObserver = new ResizeObserver((entries) => {
        if (callback) {
            const height = entries[0].target.getBoundingClientRect().height;
            callback(height);
        }
    });
    resizeObserver.observe(document.body);

    return resizeObserver;
};
