// externals
import type { RouteComponentProps } from 'react-router';

// libraries
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// routing
import { Routes } from './routes';
import { useSelector } from 'react-redux';

// types
import { ChooseVehicleStateTree } from '../types/stateTreeTypes';

export const Routing: React.FC<RouteComponentProps> = (props) => {
    const state = useSelector((state: ChooseVehicleStateTree) => state);
    const toggles = featureToggleSelectors.getFeatureToggles(state as any);

    return <Routes toggles={toggles} {...props} />;
};
